import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <div className="App">
          <h1 className="neon-text">BoJo Consulting</h1>
      </div>
  );
}

export default App;
